import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Cookies from "universal-cookie";
import {
  addSeconds,
  fromUnixTime,
  getUnixTime,
  isAfter,
  subDays,
} from "date-fns";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import {
  getCleverSubscriptions,
  getDistrictReportsForClever,
  getParentRosters,
  getSchoolReports,
  getSchoolsOfCleverDistrict,
  getSchoolSubscriptions,
  getSchoolSubscriptionsForClever,
  getTeachers,
  userGetMe,
  userRefreshToken,
} from "../api/rifService";
import {
  AUTH_CHECK_INTERVAL,
  AUTH_CHECK_THRESHOLD,
  DEFAULT_TEACHERS,
  DEFAULT_USER_DATA,
  PER_PAGE_REPORTS_FOR_CLEVER,
  SKYBRARY_CLEVER_INTERNAL_SECTION_ID,
  SKYBRARY_CLEVER_TOKEN,
  SKYBRARY_PARENT_TOKEN,
  SKYBRARY_TOKEN_EXPIRES_IN,
  SKYBRARY_USER_REFRESH_TOKEN,
  SKYBRARY_USER_TOKEN,
} from "@utils/constants";
import GiftContext from "./Forms/giftContext";
import { useSpinner } from "../providers/SpinnerProvider";

const cookies = new Cookies();

const UserContext = React.createContext({
  email: "",
  token: "",
  userData: "",
  isTrial: false,
  adminTeacher: "",
  teachers: "",
  subscriptions: "",
  schoolReports: "",
  isLoggedIn: false,
  formProgress: 0,
  loginFlag: false,
  eduUserData: "",
  fromTrial: false,
  isLogging: "",
  students: "",
  hasMultipleStudents: false,
  activatingTeacher: "",
  activatingParent: false,
  hasActiveSubscription: false,
  adminHasActiveSubscription: false,
  adminAssigned: false,
  userWithSubscription: false,
  isLoading: true,
  showErrorMsg: false,
  errorMsg: "",
  hasPosterRendered: false,
  settingPassword: false,
  parentReports: "",
  parentClassroomHashes: [],
  setIsLoading: (value) => {},
  saveTeacherActivating: () => {},
  saveParentActivating: () => {},
  saveStudents: (value) => {},
  saveIsLogging: (value) => {},
  saveFromTrial: (value) => {},
  login: (token) => {},
  loginStudent: (token) => {},
  loginCleverStudent: (token) => {},
  logout: () => {},
  setFormProgress: (progress) => {},
  saveSubscriptions: (subscription, user) => {},
  saveSchoolReports: (reports) => {},
  saveTeachers: (teacher, teacherId, user) => {},
  setIsTrialUser: (value) => {},
  changeLoginFlag: () => {},
  webappCookies: (expiresIn, userData) => {},
  setUserData: (data) => {},
  refreshUserData: (token) => {},
  saveHasPosterRendered: (value) => {},
  saveSettingPasword: (value) => {},
  saveParentReports: (reports) => {},
  saveParentClassroomHashes: (hashes) => {},
});

const retrieveStoredToken = (cookies) => {
  const storedToken = cookies.get(SKYBRARY_USER_TOKEN);

  return {
    token: storedToken,
  };
};

/**
 * Get data from Localstorage.
 * @param key
 * @returns {any}
 */
const retrieveStoredData = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    return false;
  }
};

/**
 * Stores current data in Localstorage.
 *
 * @param key
 * @param data
 */
const updateStoredData = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const UserProvider = (props) => {
  const tokenData = retrieveStoredToken(cookies);
  const userDataR = retrieveStoredData("user");
  const isTrialData = retrieveStoredData("isTrial");
  const loginFlagData = retrieveStoredData("loginFlag");
  const fromTrialData = retrieveStoredData("fromTrial");
  const isLoggingData = retrieveStoredData("logingData");
  const studentsData = retrieveStoredData("students");
  const settingPasswordData = retrieveStoredData("settingPassword");
  const activatingTeacherData = retrieveStoredData("activatingTeacher");
  const activatingParentData = retrieveStoredData("activatingParent");
  const didUserProviderRef = useRef(false);

  let initialFromTrial = false;
  let initialToken;
  let initialExpiresIn;
  let initialIsTrial = false;
  let loginFlag = false;
  let initialIsLogging = false;
  let initialStudents = false;
  let initialActivatingTeacher = false;
  let initialActivatingParent = false;
  let initialSettingPassword = false;

  if (tokenData) {
    initialToken = tokenData.token;
  }
  if (userDataR) {
    initialExpiresIn = userDataR.expiresIn;
  }
  if (isTrialData) {
    initialIsTrial = isTrialData;
  }
  if (loginFlagData) {
    loginFlag = loginFlagData;
  }
  if (fromTrialData) {
    initialFromTrial = fromTrialData;
  }
  if (isLoggingData) {
    initialIsLogging = isLoggingData;
  }
  if (studentsData) {
    initialStudents = studentsData;
  }
  if (activatingTeacherData) {
    initialActivatingTeacher = activatingTeacherData;
  }
  if (activatingParentData) {
    initialActivatingParent = activatingParentData;
  }
  if (settingPasswordData) {
    initialSettingPassword = settingPasswordData;
  }

  const changeLoginFlag = () => {
    loginFlag = true;
    localStorage.setItem("loginFlag", JSON.stringify(true));
  };

  const [token, setToken] = useState(initialToken);
  const [time, setTime] = useState(0);
  const [userData, setUserData] = useState(DEFAULT_USER_DATA);
  const [isLogging, setIsLogging] = useState(initialIsLogging);
  const [isLoading, setIsLoading] = useState(true);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorFrom, setErrorFrom] = useState("");
  let userIsLoggedIn = Boolean(token);
  const { showSpinner } = useSpinner();

  const [hasPosterRendered, setHasPosterRendered] = useState(false);

  /**
   * Returns all cookies
   * @returns {{userToken: *, parentToken: *, refreshToken: *}}
   */
  const getStudentTokens = () => {
    return {
      userToken: cookies.get(SKYBRARY_USER_TOKEN),
      refreshToken: cookies.get(SKYBRARY_USER_REFRESH_TOKEN),
      parentToken: cookies.get(SKYBRARY_PARENT_TOKEN),
    };
  };
  /**
   * Clears all cookies from student users
   */
  const deleteStudentTokens = () => {
    const params = {
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    };
    cookies.remove(SKYBRARY_USER_TOKEN, params);
    cookies.remove(SKYBRARY_CLEVER_TOKEN, params);
    cookies.remove(SKYBRARY_CLEVER_INTERNAL_SECTION_ID, params);
    cookies.remove(SKYBRARY_USER_REFRESH_TOKEN, params);
    cookies.remove(SKYBRARY_PARENT_TOKEN, params);
  };

  /**
   * Handles User authentication status.
   *
   * @type {(function(): void)|*}
   */
  const authHandler = () => {
    // If no auth token, skip this check
    if (userIsLoggedIn) {
      const path = window.location.pathname;
      const currentDate = new Date();
      const expireDate = fromUnixTime(cookies.get(SKYBRARY_TOKEN_EXPIRES_IN));
      const limitDate = subDays(expireDate, AUTH_CHECK_THRESHOLD);
      const user = retrieveStoredData("user");

      if (currentDate > expireDate) {
        // Close session
        showSpinner();
        logoutHandler();

        // Check if user is located in a logged url
        if (path.includes("adm") || path.includes("teachers")) {
          setTimeout(() => {
            window.location.href = "/unauthorized";
          }, 2500);
        }
      } else if (
        user != null &&
        !user?.edu?.clever &&
        isAfter(currentDate, limitDate)
      ) {
        // This logic is only for non-clever users.
        // Clever users do not have posibility to refresh token.
        const token = cookies.get(SKYBRARY_USER_TOKEN);
        const refreshToken = cookies.get(SKYBRARY_USER_REFRESH_TOKEN);

        userRefreshToken(token, refreshToken).then((refreshResponse) => {
          refreshAuthHandler(refreshResponse.data);
        });
      } else {
        // authenticated
        console.log("authenticated");
      }
    } else {
      console.log("anonymous");
      // anonymous
    }
  };

  /**
   * Logout user and clear all data related.
   *
   * @type {(function(): void)|*}
   */
  const logoutHandler = useCallback(() => {
    const cookieParams = {
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    };
    cookies.remove(SKYBRARY_USER_TOKEN, cookieParams);
    cookies.remove(SKYBRARY_TOKEN_EXPIRES_IN, cookieParams);
    cookies.remove(SKYBRARY_CLEVER_TOKEN, cookieParams);
    cookies.remove(SKYBRARY_CLEVER_INTERNAL_SECTION_ID, cookieParams);
    cookies.remove(SKYBRARY_USER_REFRESH_TOKEN, cookieParams);
    cookies.remove("Drupal.visitor.rrkuser", cookieParams);
    localStorage.clear();
    sessionStorage.clear();
    setIsTrialUser(false);
    loginFlag = false;
  }, []);

  /**
   * Setup user data in React context, cookies and localstorage.
   *
   * @param data
   */
  const loginHandler = (data) => {
    // Clear student session before start user session.
    const studentTokens = getStudentTokens();
    if (Boolean(studentTokens.parentToken)) {
      deleteStudentTokens();
    }
    // Clear previous error messages.
    setShowErrorMsg(false);
    setErrorMsg("");
    // Set user session data.
    setToken(data.auth.accessToken);
    localStorage.setItem("token", JSON.stringify(data.auth.accessToken));
    setUserData(data.userResponse.data);
    localStorage.setItem("user", JSON.stringify(data.userResponse.data));

    let utcDateExpireIn;
    let epoxExpireIn;

    if (data.userResponse.data?.edu?.clever) {
      utcDateExpireIn = getUnixTime(new Date(data.auth.expiresIn));
      epoxExpireIn = new Date(data.auth.expiresIn);
    } else {
      utcDateExpireIn = getUnixTime(new Date()) + data.auth.expiresIn;
      epoxExpireIn = addSeconds(new Date(), data.auth.expiresIn);
    }

    data.userResponse.data.expiresIn = utcDateExpireIn;
    data.userResponse.data.refreshToken = data.auth.refreshToken;
    const cookieParams = {
      path: "/",
      secure: process.env.REACT_APP_COOKIE_DOMAIN !== ".lndo.site",
      sameSite: "lax",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    };
    cookies.set(SKYBRARY_USER_TOKEN, data.auth.accessToken, cookieParams);

    const webappUserCookie = {
      expiresIn: utcDateExpireIn,
      refreshToken: data.auth.refreshToken,
    };

    cookies.set(
      SKYBRARY_USER_REFRESH_TOKEN,
      btoa(encodeURIComponent(JSON.stringify(webappUserCookie))),
      cookieParams,
    );

    // Set expiration date
    cookies.set(SKYBRARY_TOKEN_EXPIRES_IN, utcDateExpireIn, cookieParams);

    if (!data.userResponse?.data?.edu?.clever) {
      cookies.set(
        SKYBRARY_USER_REFRESH_TOKEN,
        data.auth.refreshToken,
        cookieParams,
      );
    }

    const rrkWebappUserId = btoa(`id=${data.userResponse.data.userId};`);
    cookies.set("Drupal.visitor.rrkuser", rrkWebappUserId, cookieParams);
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  };

  function setAuthTokenForStudent(data) {
    const cookieParams = {
      path: "/",
      secure: process.env.REACT_APP_COOKIE_DOMAIN !== ".lndo.site",
      sameSite: "lax",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    };
    cookies.set(SKYBRARY_USER_TOKEN, data.auth.accessToken, cookieParams);
    const webappUserCookie = {
      expiresIn: data.auth.expiresIn,
      refreshToken: data.auth.refreshToken,
    };
    cookies.set(
      SKYBRARY_USER_REFRESH_TOKEN,
      btoa(encodeURIComponent(JSON.stringify(webappUserCookie))),
      cookieParams,
    );
    if (data.auth.expiresIn > 0) {
      let studentData = DEFAULT_USER_DATA;
      const epoxExpireIn = data.auth.expiresIn / 1000;
      studentData.expiresIn = epoxExpireIn;
      updateStoredData("user", studentData);
      cookies.set(SKYBRARY_TOKEN_EXPIRES_IN, epoxExpireIn, cookieParams);
    }
    return cookieParams;
  }

  const loginCleverStudentHandler = (data) => {
    return setAuthTokenForStudent(data);
  };

  const loginStudentHandler = (data) => {
    const cookieParams = setAuthTokenForStudent(data);
    cookies.set(SKYBRARY_PARENT_TOKEN, data.user.parentToken, cookieParams);
    return cookieParams;
  };

  /**
   * Updates user session.
   *
   * @param data
   */
  const refreshAuthHandler = (data) => {
    let expirTime;
    let date;
    let user = retrieveStoredData("user");

    if (!data) {
      return;
    }

    expirTime = getUnixTime(new Date()) + data.auth.expiresIn;
    date = addSeconds(new Date(), data.auth.expiresIn);

    setToken(data.auth.accessToken);

    user.expiresIn = expirTime;
    user.refreshToken = data.auth.refreshToken;
    localStorage.setItem("user", JSON.stringify(user));

    const cookieParams = {
      path: "/",
      secure: process.env.REACT_APP_COOKIE_DOMAIN !== ".lndo.site",
      sameSite: "lax",
      expires: date,
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    };
    cookies.set(SKYBRARY_USER_TOKEN, data.auth.accessToken, cookieParams);
    cookies.set(
      SKYBRARY_USER_REFRESH_TOKEN,
      data.auth.refreshToken,
      cookieParams,
    );
    cookies.set(SKYBRARY_TOKEN_EXPIRES_IN, expirTime, cookieParams);
  };

  const saveFromTrial = (value) => {
    initialFromTrial = value;
    localStorage.setItem("fromTrial", JSON.stringify(value));
  };

  const saveIsLogging = (value) => {
    setIsLogging(value);
    localStorage.setItem("isLogging", JSON.stringify(value));
  };

  const [activatingTeacher, setActivatingTeacher] = useState(
    initialActivatingTeacher,
  );

  const saveTeacherActivating = () => {
    setActivatingTeacher(true);
    localStorage.setItem("activatingTeacher", JSON.stringify(true));
  };

  const [activatingParent, setActivatingParent] = useState(
    initialActivatingParent,
  );

  const saveParentActivating = () => {
    setActivatingParent(true);
    localStorage.setItem("activatingParent", JSON.stringify(true));
  };

  const [subscriptions, setSubscriptions] = useState([]);
  const [districtSchools, setDistrictSchools] = useState([]);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [adminHasActiveSubscription, setAdminHasActiveSubscription] =
    useState(false);
  const [cleverAdminHasSchools, setCleverAdminHasSchools] = useState(false);
  const [adminAssigned, setAdminAssigned] = useState(false);
  const [userWithSubscription, setUserWithSubscription] = useState(false);

  const saveSubscriptions = (subscriptions, user) => {
    setSubscriptions(subscriptions);
    setHasActiveSubscription(false);
    setAdminHasActiveSubscription(false);
    setUserWithSubscription(false);

    const activeSub = subscriptions.some(subscription => {
      const expirationTimestamp = subscription.expirationDate;
      const currentTimestamp = new Date().getTime();
      return expirationTimestamp > currentTimestamp;
    });

    const adminActiveSub = subscriptions.some(subscription => {
      const expirationTimestamp = subscription.expirationDate;
      const currentTimestamp = new Date().getTime();

      return (
        expirationTimestamp > currentTimestamp &&
        user.type === "admin" &&
        subscription.teacherId === user.relatedAccountId
      );
    });

    const adminInClassroom = subscriptions.some(subscription =>
      user.type === "admin" && subscription.teacherId === user.relatedAccountId
    );

    if (subscriptions.length > 0) {
      setUserWithSubscription(true);
    }

    setHasActiveSubscription(activeSub);
    setAdminHasActiveSubscription(adminActiveSub);
    setAdminAssigned(adminInClassroom);
  };

  const saveDistrictSchools = (schools) => {
    console.log("save schools set", schools);
    localStorage.setItem("districtSchools", JSON.stringify(schools));
    setDistrictSchools(schools);
    setCleverAdminHasSchools(schools.length > 0);
  };

  const [students, setStudents] = useState(initialStudents);

  const saveStudents = (student) => {
    setStudents(student);
    localStorage.setItem("students", JSON.stringify(student));
  };

  const hasMultipleStudents = userData.children.length > 1;

  const [teachers, setTeachers] = useState(DEFAULT_TEACHERS);
  const [adminTeacher, setAdminTeacher] = useState([]);

  const saveTeachers = (teachers, teacherId, user, isClever = false) => {
    let filteredTeacher = [];
    if (isClever) {
      console.log(
        "set teacher clever",
        teacherId,
        user,
        teachers.data.teachers,
      );
      setTeachers(teachers.data.teachers);
      setAdminTeacher(teachers.data.teachers);
    } else {
      if (user === "admin" && teachers.data.teachers.length > 0) {
        filteredTeacher = teachers.data.teachers.filter(
          (teacher) => teacher.edu.eduUserId === teacherId,
        );
      }
      setTeachers(teachers);
      setAdminTeacher(filteredTeacher);
    }
  };

  const [schoolReports, setSchoolReports] = useState([]);

  const saveSchoolReports = (reports) => {
    setSchoolReports(reports);
  };

  const [parentReports, setParentReports] = useState([]);

  const saveParentReports = (reports) => {
    setParentReports(reports);
  };

  const [parentClassroomHashes, setParentClassroomHashes] = useState([]);

  const saveParentClassroomHashes = (hashes) => {
    setParentClassroomHashes(hashes);
  };

  const [isTrial, setIsTrial] = useState(initialIsTrial);

  const setIsTrialUser = (value) => {
    setIsTrial(value);
    localStorage.setItem("isTrial", JSON.stringify(value));
  };

  const errorHandler = (error, fromEndpoint) => {
    setErrorFrom(fromEndpoint);
    setErrorMsg(
      "Unable to load this page. Please refresh. If the problem persist, please contact support.",
    );
    setShowErrorMsg(true);
    setIsLoading(false);
  };

  const saveHasPosterRendered = (value) => {
    setHasPosterRendered(value);
  };

  const [settingPassword, setSettingPassword] = useState(
    initialSettingPassword,
  );

  const saveSettingPassword = (value) => {
    setSettingPassword(value);
    localStorage.setItem("settingPassword", JSON.stringify(value));
  };

  /**
   * Fetch from API data from authenticated user and updates Context.
   *
   * @param token
   */
  const refreshUserData = async (token) => {
    let cleverToken = cookies.get(SKYBRARY_CLEVER_TOKEN);
    if (!hasPosterRendered) {
      const requestConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
          ...(cleverToken && { cleveraccesstoken: cleverToken }),
        },
      };

      if (token && !settingPassword) {
        const userInfo = await userGetMe(token);

        let userResp = userInfo.data || {}; //For when userGetMe returns 200 but the body response is an error

        if (userInfo.error) {
          errorHandler(userInfo.error, "userGetMe");
          return;
        }

        if (userResp.hasOwnProperty("errorCode")) {
          if (userInfo.errorCode != 401) {
            setErrorMsg(
              "Unable to load this page. Please refresh. If the problem persist, please contact support.",
            );
          }
          setShowErrorMsg(true);
          setIsLoading(false);
        } else {
          setUserData(userInfo?.data);

          //Set isTrial for teacher user type
          if (userInfo.data.edu.user.type === "teacher") {
            if (!userInfo.data.edu.user.isTrial) {
              setIsTrialUser(false);
            }
          }

          let schoolSubscriptions = [];
          const schoolId = userInfo.data.edu.user.rrkSchoolId;
          let cleverUserFound = userInfo.data.edu.clever;

          if (cleverUserFound) {
            console.log("is clever user");
            setIsLoading(false);
            if (userInfo.data.edu.user.type === "admin") {
              console.log("is admin here 1");
              //saveTeachers([], null, 'admin')

              schoolSubscriptions = await getCleverSubscriptions(
                requestConfig,
                false,
                1,
              );
              if (schoolSubscriptions.error) {
                errorHandler(
                  schoolSubscriptions.error,
                  "getCleverSubscriptions",
                );
              } else {
                saveSubscriptions(
                  schoolSubscriptions.data,
                  userInfo.data.edu.user,
                );
              }

              let schools = await getSchoolsOfCleverDistrict(requestConfig);
              let schoolsIds = [];
              if (schools.data) {
                saveDistrictSchools(schools.data);
                schoolsIds = schools.data.map(
                  (cleverSchool) => cleverSchool.schoolId,
                );
              }

              const reportResponse = await getDistrictReportsForClever(
                requestConfig,
                null,
                PER_PAGE_REPORTS_FOR_CLEVER,
                1,
              );
              if (!reportResponse.error) {
                saveSchoolReports(reportResponse);
              }
              setIsLoading(false);
            }

            if (userInfo.data.edu.user.type === "teacher") {
              console.log("teacher clever user found in user js");
              schoolSubscriptions = await getSchoolSubscriptionsForClever(
                requestConfig,
                10,
                1,
              );
              if (schoolSubscriptions.error) {
                errorHandler(
                  schoolSubscriptions.error,
                  "getSchoolSubscriptionsForClever",
                );
              }

              saveTeachers(teachers, null, "teacher");
              saveSubscriptions(schoolSubscriptions, userInfo.data.edu.user);

              const reports = await getDistrictReportsForClever(
                requestConfig,
                userInfo.data.edu.user.rrkEduUserId,
                PER_PAGE_REPORTS_FOR_CLEVER,
                1,
              );
              if (reports.error) {
                errorHandler(reports.error, "getDistrictReportsForClever");
              } else {
                saveSchoolReports(reports);
                console.log("saving reports clever");
                setIsLoading(false);
              }
            }
          } else {
            console.log("No clever user");
            let schoolSubscriptions;
            let teachers = { data: [] };

            if (userInfo.data.edu.user.type === "admin") {
              schoolSubscriptions = await getSchoolSubscriptions(
                requestConfig,
                schoolId,
                null,
              );

              if (schoolSubscriptions.error) {
                errorHandler(
                  schoolSubscriptions.error,
                  "getSchoolSubscriptions",
                );
              }

              teachers = await getTeachers(requestConfig, "edu");
              if (teachers.error) {
                errorHandler(teachers.error, "getTeachers");
              } else {
                saveTeachers(
                  teachers,
                  userInfo.data.edu.user.relatedAccountId,
                  "admin",
                );
              }

              saveSubscriptions(schoolSubscriptions, userInfo.data.edu.user);
              const reports = await getSchoolReports(requestConfig, schoolId);
              if (reports.error) {
                errorHandler(reports.error, "getSchoolReports");
              } else {
                saveSchoolReports(reports);
                setIsLoading(false);
              }
            }

            if (userInfo.data.edu.user.type === "teacher") {
              schoolSubscriptions = await getSchoolSubscriptions(
                requestConfig,
                schoolId,
                userInfo.data.edu.user.rrkEduUserId,
              );

              if (schoolSubscriptions.error) {
                errorHandler(
                  schoolSubscriptions.error,
                  "getSchoolSubscriptions",
                );
              }
              saveTeachers(teachers, null, "teacher");
              saveSubscriptions(schoolSubscriptions, userInfo.data.edu.user);
              const reports = await getSchoolReports(requestConfig, schoolId);
              if (reports.error) {
                errorHandler(reports.error, "getSchoolReports");
              } else {
                saveSchoolReports(reports);
                setIsLoading(false);
              }
            }

            //No clever user found
            getParentRosters(
              userInfo.data.edu.user.rrkEduUserId,
              requestConfig,
            ).then((parentReports) => {
              saveParentReports(parentReports.data);
            });
          }
        }
      } else {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!didUserProviderRef.current && token) {
      console.log("Refreshing user data...");
      refreshUserData(token);
      didUserProviderRef.current = true;
    }
    let timer = setTimeout(() => {
      console.log("Checking authentication...");
      authHandler();
      setTime(time + 1);
    }, AUTH_CHECK_INTERVAL);
    return () => {
      clearTimeout(timer);
    };
  }, [time]);

  const [formProgress, setFormProgress] = useState(0);
  const formProgressHandler = (progress) => {
    setFormProgress(progress);
    if (progress === 100) {
      setFormProgress(0);
    }
  };

  const setWebAppCookies = (expiresIn, userData) => {
    const date = addSeconds(new Date(), expiresIn);

    const cookieParams = {
      path: "/",
      secure: process.env.REACT_APP_COOKIE_DOMAIN !== ".lndo.site",
      sameSite: "lax",
      expires: date,
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    };

    let rrkWebappUserId = btoa(`id=${userData.userId};`);

    if (userData.userType === "student") {
      rrkWebappUserId = btoa(
        `id=${userData.userId};child_id=${userData.studentId};user_type=student;email=${userData.email};`,
      );
    }

    cookies.set("Drupal.visitor.rrkuser", rrkWebappUserId, cookieParams);
  };

  const contextValue = {
    token: token,
    userData: userData,
    subscriptions: subscriptions,
    isTrial: isTrial,
    loginFlag: loginFlag,
    isLoggedIn: userIsLoggedIn,
    formProgress: formProgress,
    adminTeacher: adminTeacher,
    teachers: teachers,
    schoolReports: schoolReports,
    fromTrial: initialFromTrial,
    isLogging: isLogging,
    students: students,
    hasMultipleStudents: hasMultipleStudents,
    activatingTeacher: activatingTeacher,
    activatingParent: activatingParent,
    hasActiveSubscription: hasActiveSubscription,
    userWithSubscription: userWithSubscription,
    adminHasActiveSubscription: adminHasActiveSubscription,
    adminAssigned: adminAssigned,
    isLoading: isLoading,
    showErrorMsg,
    errorMsg,
    errorFrom,
    hasPosterRendered,
    parentReports: parentReports,
    parentClassroomHashes: parentClassroomHashes,
    setIsLoading: setIsLoading,
    saveTeacherActivating: saveTeacherActivating,
    saveParentActivating: saveParentActivating,
    saveStudents: saveStudents,
    saveIsLogging: saveIsLogging,
    saveFromTrial: saveFromTrial,
    saveTeachers: saveTeachers,
    saveSchoolReports: saveSchoolReports,
    changeLoginFlag: changeLoginFlag,
    setIsTrialUser: setIsTrialUser,
    saveSubscriptions: saveSubscriptions,
    login: loginHandler,
    loginStudent: loginStudentHandler,
    loginCleverStudent: loginCleverStudentHandler,
    logout: logoutHandler,
    setFormProgress: formProgressHandler,
    webappCookies: setWebAppCookies,
    setUserData: setUserData,
    refreshUserData: refreshUserData,
    saveHasPosterRendered: saveHasPosterRendered,
    saveSettingPassword: saveSettingPassword,
    saveParentReports: saveParentReports,
    saveParentClassroomHashes: saveParentClassroomHashes,
    saveDistrictSchools: saveDistrictSchools,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);

export const RequireAuth = () => {
  const userContext = useContext(UserContext);
  const parentCookie = cookies.get(SKYBRARY_PARENT_TOKEN) || "";

  if (
    !userContext.isLoggedIn ||
    (userContext.isLoggedIn && parentCookie !== "")
  ) {
    localStorage.removeItem("lastURL");
    window.location.replace("/unauthorized");
    return <></>;
  }

  return <Outlet />;
};

export const ManageUserTeacher = (props) => {
  const userContext = useContext(UserContext);
  const location = useLocation();

  if (userContext.userData.edu.user.migrationRequest !== null) {
    return <Navigate to={{ pathname: "/teachers/migrate/confirm" }} replace />;
  }

  if (userContext.userData.type === "edu-parent") {
    return (
      <Navigate
        to={{ pathname: "/unauthorized", state: { from: location } }}
        replace
      />
    );
  }

  return <Outlet />;
};

export const ManageSetPassword = () => {
  const userContext = useContext(UserContext);
  const location = useLocation();

  if (userContext.activatingTeacher || userContext.activatingParent) {
    return <Outlet />;
  }

  //Recover context data
  let userData = userContext.userData;
  let isLogging = userContext.isLogging;
  let fromTrial = userContext.userData.edu.school.orders.fromTrial;

  if (!userContext.userData.emailAddress) {
    userData = retrieveStoredData("user");
    if (userData !== null) {
      isLogging = retrieveStoredData("isLogging");
      fromTrial = userData.edu.school.orders.fromTrial;
      userContext.setUserData(userData);
      userContext.saveIsLogging(isLogging);
      userContext.saveFromTrial(fromTrial);
    } else {
      return <Navigate to={{ pathname: "/unauthorized" }} replace />;
    }
  }

  if (userData.type === "edu-teacher" && userData.edu.user.acceptTOS) {
    return (
      <Navigate
        to={{ pathname: "/teachers/dashboard", state: { from: location } }}
        replace
      />
    );
  } else if (userData.type === "edu-admin" && userData.edu.user.acceptTOS) {
    return (
      <Navigate
        to={{ pathname: "/adm/dashboard", state: { from: location } }}
        replace
      />
    );
  }
  if (
    (!userData.edu.user.userFirstLogin && userData.userId !== null) ||
    fromTrial ||
    isLogging ||
    !userData.edu.user.acceptTOS
  ) {
    return <Outlet />;
  } else {
    return <Navigate to={{ pathname: "/unauthorized" }} replace />;
  }
};

export const ManageUserAdmin = () => {
  const userContext = useContext(UserContext);
  const location = useLocation();
  if (userContext.userData.type === "edu-teacher") {
    return (
      <Navigate
        to={{ pathname: "/unauthorized", state: { from: location } }}
        replace
      />
    );
  } else if (userContext.userData.type === "edu-admin") {
    return <Outlet />;
  } else if (userContext.userData.type === "edu-parent") {
    return (
      <Navigate
        to={{ pathname: "/unauthorized", state: { from: location } }}
        replace
      />
    );
  }
  return null;
};

export const ManageAnonymous = () => {
  const userContext = useContext(UserContext);
  const giftContext = useContext(GiftContext);

  if (userContext.loginFlag && !userContext.fromTrial) {
    return <Navigate to={{ pathname: giftContext.prevUrl }} replace />;
  }

  return <Outlet />;
};

export const ManageOrder = () => {
  const userContext = useContext(UserContext);

  if (userContext.hasActiveSubscription) {
    return <Outlet />;
  } else {
    return <Navigate to={{ pathname: "/adm/dashboard" }} replace />;
  }
};

export const ManageTrial = () => {
  const userContext = useContext(UserContext);

  if (userContext.isTrial) {
    return <Outlet />;
  } else {
    return <Navigate to={{ pathname: "/unauthorized" }} replace />;
  }
};

export const ManageUserParent = () => {
  const userContext = useContext(UserContext);
  const location = useLocation();

  if (userContext.userData.type === "edu-parent") {
    return <Outlet />;
  } else {
    return (
      <Navigate
        to={{ pathname: "/unauthorized", state: { from: location } }}
        replace
      />
    );
  }
};

export default UserContext;
