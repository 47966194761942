import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

/**
 * Get data from Localstorage.
 * @param key
 * @returns {any}
 */
const retrieveStoredData = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    return false;
  }
};

/**
 * Stores current data in Localstorage.
 *
 * @param key
 * @param data
 */
const updateStoredData = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const CleverContext = React.createContext();

export function CleverProvider({ children }) {
  const [comeFromGiftRedeemPage, setComeFromGiftRedeemPage] = useState(false);
  const [role, setRole] = useState("");
  const [summaryData, setSummaryData] = useState(null);
  const [giftCode, setGiftCode] = useState("");
  const [giftDistrict, setGiftDistrict] = useState("");
  const [giftCleverSubscriptions, setCleverSubscriptions] = useState("");
  const [cleverTeachers, setCleverTeachers] = useState("");
  const [renewSubscriptions, setRenewSubscriptions] = useState("");

  const isDistrictStaff = () => {
    return retrieveStoredData("cleverRole") === "staff";
  };

  const isDistrictAdmin = () => {
    return retrieveStoredData("cleverRole") === "district_admin";
  };

  const isStudent = () => {
    return retrieveStoredData("cleverRole") === "student";
  };

  const isTeacher = () => {
    return retrieveStoredData("cleverRole") === "teacher";
  };

  // For pages where the user is logged in we will use the isCleverUser() function and for the rest of the cases the isCleverTheme(userContext, cleverContext).
  const isCleverUser = () => {
    return isTeacher() || isStudent() || isDistrictAdmin() || isDistrictStaff();
  };

  const saveRole = (role) => {
    setRole(role);
    updateStoredData("cleverRole", role);
  };

  const saveComeFromGiftRedeemPage = (value) => {
    setComeFromGiftRedeemPage(value);
    updateStoredData("comeFromGiftRedeemPage", value);
  };

  const saveCleverGiftToken = (value) => {
    setGiftCode(value);
    updateStoredData("giftToken", value);
  };

  const getCleverGiftToken = () => {
    return retrieveStoredData("giftToken");
  };

  const getCleverRole = () => {
    return retrieveStoredData("cleverRole");
  };

  const giftForClever = () => {
    return retrieveStoredData("comeFromGiftRedeemPage");
  };

  const saveComePurchasePage = (value) => {
    setComeFromGiftRedeemPage(value);
    updateStoredData("comeFromPurchasePage", value);
  };

  const fromPurchasePage = () => {
    return retrieveStoredData("comeFromPurchasePage");
  };

  const saveSummaryData = (value) => {
    setSummaryData(value);
    updateStoredData("summaryData", value);
  };

  const getSummaryData = () => {
    return retrieveStoredData("summaryData");
  };

  const saveCleverGiftDistrict = (value) => {
    setGiftDistrict(value);
    updateStoredData("giftDistrict", value);
  };

  const getCleverGiftDistrict = () => {
    return retrieveStoredData("giftDistrict");
  };

  const saveCleverSubscriptions = (value) => {
    setCleverSubscriptions(value);
    updateStoredData("cleverSubscriptions", value);
  };

  const getCleverSubscriptions = () => {
    return retrieveStoredData("cleverSubscriptions");
  };

  const saveCleverTeachers = (value) => {
    setCleverSubscriptions(value);
    updateStoredData("cleverTeachers", value);
  };
  const getCleverTeachers = () => {
    return retrieveStoredData("cleverTeachers");
  };

  const getDashboardInfo = () => {
    return retrieveStoredData("dashboardInfo");
  };

  const saveRenewSubscriptions = (value) => {
    setRenewSubscriptions(value);
    updateStoredData("renewSubscriptions", value);
  };
  const getRenewSubscriptions = () => {
    return retrieveStoredData("renewSubscriptions");
  };

  const contextValue = {
    giftForClever,
    saveComeFromGiftRedeemPage,
    saveRole,
    isDistrictStaff,
    isDistrictAdmin,
    isStudent,
    isTeacher,
    fromPurchasePage,
    saveComePurchasePage,
    getCleverRole,
    isCleverUser,
    saveSummaryData,
    getSummaryData,
    saveCleverGiftToken,
    getCleverGiftToken,
    saveCleverGiftDistrict,
    getCleverGiftDistrict,
    saveCleverSubscriptions,
    getCleverSubscriptions,
    saveCleverTeachers,
    getCleverTeachers,
    getDashboardInfo,
    saveRenewSubscriptions,
    getRenewSubscriptions,
  };

  return (
    <CleverContext.Provider value={contextValue}>
      {children}
    </CleverContext.Provider>
  );
}

export const useCleverContext = () => useContext(CleverContext);

export const RequiredDistrictAdmin = () => {
  const cleverContext = useContext(CleverContext);
  useEffect(() => {
    console.log("request dis admin", cleverContext);
    if (!cleverContext.isDistrictAdmin()) {
      return <>Not allowed, you should be district admin</>;
    }
    return <Outlet />;
  }, []);
};

export const NotForCleverUser = () => {
  const cleverContext = useContext(CleverContext);
  if (cleverContext.isCleverUser()) {
    return <>Not allowed for clever User</>;
  }
  return <Outlet />;
};

export default CleverContext;
