import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect
} from "react";
import { getDashboardInfo } from "../api/rifService";
import { DEFAULT_DASHBOARD_DATA } from '../utils/constants'
import UserContext from "./user";

const DashboardContext = createContext({
  information: DEFAULT_DASHBOARD_DATA,
  setInfo: (token) => {},
});

const retrieveDashboardInfo = () => {
  try {
    const recoveredDashbaordInfo = JSON.parse(localStorage.getItem("dashboardInfo"));
    if (recoveredDashbaordInfo && recoveredDashbaordInfo.length) {
      return recoveredDashbaordInfo
    }
    return DEFAULT_DASHBOARD_DATA;
  } catch (e) {
    return false;
  }
};

export const InformationProvider = (props) => {
  const userContext = useContext(UserContext);
  const dashboardInfoData = retrieveDashboardInfo();
  const didDashboardProviderRef = useRef(false);
  let initialData = DEFAULT_DASHBOARD_DATA;

  if (dashboardInfoData) {
    initialData = dashboardInfoData;
  }

  const [dataState, setDataState] = useState(initialData);


  const setDashboardInformationHandler = (token) => {
    const requestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    getDashboardInfo(requestConfig).then((response) => {
      const res = JSON.stringify(response.data)
      if (res.length) {
        localStorage.setItem("dashboardInfo", res);
        setDataState(response.data);
      }
    });
  };

  const contextValue = {
    information: dataState,
    setInfo: setDashboardInformationHandler,
  };

  useEffect(() => {
    if (!didDashboardProviderRef.current && userContext.token &&
      (dashboardInfoData === null || !dashboardInfoData.length)
    ) {
      setDashboardInformationHandler(userContext.token)
      didDashboardProviderRef.current = true
    }
  }, [userContext.token]);

  return (
    <DashboardContext.Provider value={contextValue}>
      {props.children}
    </DashboardContext.Provider>
  );
};

export default DashboardContext;
